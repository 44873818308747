import { SEARCH_BUSINESSES_ANY_STATUS } from 'common/consts';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'http://localhost:3000';
// const BASE_URL = 'https://faveapp-dev.ambitiousplant-6f28b80d.eastus2.azurecontainerapps.io';
console.log("process", process.env)
/**
 * Fetch data wrapper.
 * TODO: Merge with requestFormat and related functions.
 * @param {options.path} The path to fetch data from.
 * @param {options.query?} Optional query parameters object.
 * @returns The response JSON.
 */
async function fetchData({ method, path, query, contentType, content, errorCodes }) {
    let results;
    let error;
    try {
        const headers = {
            accept: 'application/json',
        };
        const resource = new URL(path, `${BASE_URL}`);
        if (query) {
            for (const [key, value] of Object.entries(query)) {
                resource.searchParams.append(key, value);
            }
        }
        if (contentType) {
            headers['content-type'] = contentType;
        }
        let body;
        if (content) {
            if (contentType === 'application/x-www-form-urlencoded') {
                body = new URLSearchParams(content).toString();
            } else if (contentType === 'application/json') {
                body = JSON.stringify(content);
            } else if (contentType === 'text/plain') {
                body = content;
            } else {
                throw new Error(`Unsupported content type ${contentType}`);
            }
        }
        const res = await fetchWrapper(resource, {
            method,
            headers,
            body,
        });
        if ([200, 201].includes(res.status)) {
            // OK, Created
            results = await res.json();
        } else if (res.status === 204) {
            // No Content
            results = {};
        } else if (
            (res.status >= 400 && res.status < 500) || // Client Errors
            (res.status >= 500 && res.status < 600) // Server Errors
        ) {
            let text;
            let data;
            try {
                data = await res.json();
            } catch {
                text = await res.text();
            }
            error = {
                data,
                text,
                status: res.status,
            };
        } else {
            error = {
                status: res.status,
            };
        }
        if (error) {
            // Examine response to determine error code.
            let code;
            if (errorCodes) {
                for (const [codeKey, codeValue] of Object.entries(errorCodes)) {
                    if (codeValue.status === error.status) {
                        if (codeValue.data && typeof codeValue.data === 'object' && error.data) {
                            const errorData = error.data;
                            const found = Object.entries(codeValue.data).every(
                                ([dataKey, dataValue]) => {
                                    if (typeof dataValue === 'string') {
                                        return errorData[dataKey] === dataValue;
                                    }
                                    if (dataValue instanceof RegExp) {
                                        return dataValue.test(errorData[dataKey]);
                                    }
                                    return false;
                                },
                            );
                            if (found) {
                                code = codeKey;
                                break;
                            }
                        } else {
                            code = codeKey;
                            break;
                        }
                    }
                }
            }
            error.code = code;
        }
    } catch (err) {
        results = undefined;
        error = {
            data: undefined,
            status: 0,
            cause: err,
        };
    }
    if (error && !error.code) {
        // Fallback to unknown error code.
        error.code = 'UNKNOWN_ERROR';
    }
    return [results, error];
}

async function requestFormat(path, params, l) {
    const defaultRequestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    };

    const requestOptions = l || defaultRequestOptions;

    const data = { payload: null, errorMessage: '', errorCode: 0 };

    const res = await fetchWrapper(`${path}`, requestOptions);

    if (res.status === 200 || res.status === 201 || res.status === 203) {
        data.payload = await res.json();

        if (!data.payload.id && data.payload._id) {
            data.payload.id = data.payload._id;
        }
    } else if (res.status === 400 || res.status === 403 || res.status === 401) {
        const contentType = res.headers.get('content-type');

        if (contentType.includes('application/json')) {
            const response = await res.json();
            data.code = response?.code;
            data.responseText = response?.status;
        } else {
            data.responseText = await res.text();
        }

        data.errorMessage = 'USER';
        data.errorCode = res.status;
        data.payload = { loading: false };
        throw data;
    } else {
        data.errorMessage = 'SERVER';
        data.errorCode = res.status;
        data.responseText = await res.text();
        data.payload = { loading: false };
        throw data;
    }
    return data;
}


const fetchWrapper = (path, params) => {
    const isAbsoluteURL = /^https?:\/\//i.test(path);

    const url = isAbsoluteURL ? path : `${BASE_URL}${path}`;
    return fetch(url, {...params, credentials: 'include'});
} 


async function formDataRequestFormat(path, params, requestOptionsOverride) {
    const requestOptions = {
        method: 'POST',
        body: params,
        ...requestOptionsOverride,
    };
    let forwardSlash = "/";
    if(path.indexOf("/")==0){
        forwardSlash = "";
    }
    return requestFormat(`${path}`, params, requestOptions);
}

export async function SendLoginUsingCookie() {
    try {
        return requestFormat('/cookie-login', {});
    } catch {
        // eslint-disable-next-line no-console
        console.log('invalid cookie');
    }
}

export async function Signup(params) {
    return requestFormat('/signup', params);
}

export async function GetBusinessMapping(groupId, depth = 1) {
    const res = await fetchWrapper(`/tags-v2?startingTagId=${groupId}&direction=down&depth=${depth}`);
    return await res.json();
}

export async function GetAllTags() {
    const res = await fetchWrapper('/group-mapping?groupId=ALL');
    return res.json();
}

export async function GetOwnedBusiness(businessId) {
    const res = await fetchWrapper(`/entity-secure-v2?id=${businessId}`);
    return res.json();
}

export async function GetCategories() {
    const res = await fetchWrapper('/business-categories');
    return res.json();
}

export async function GetSubmissions(searchText) {
    const res = await fetchWrapper(
        `/business/my-submissions?searchText=${encodeURIComponent(searchText ?? '')}`,
    );
    return res.json();
}

export async function GetProductItems(businessId, searchText) {
    const res = await fetchWrapper(
        `/business/product-items-search?business_id=${businessId}&searchText=${encodeURIComponent(
            searchText,
        )}`,
    );
    return res.json();
}

export async function Login(params) {
    return requestFormat('/login', params);
}

export async function Logout() {
    return requestFormat('/signout', {});
}

export async function Authenticate(params, path) {
    return requestFormat(path, params);
}

export async function OwnBusiness(params) {
    return formDataRequestFormat('/entity-v2', params);
}

export async function EditOwnBusiness(formData) {
    return formDataRequestFormat('/entity-v2', formData, {
        method: 'PUT',
        body: formData,
    });
}

export async function RecommendBusiness(params) {
    return requestFormat('/submit-recommend-business', params);
}
export async function EditItem(params) {
    // await formDataRequestFormat("/item/edit", params, {
    //   method: "POST",
    //   body: JSON.stringify(params),
    //   headers: { "Content-Type": "application/json" },
    // });
    return formDataRequestFormat('/item/edit', params);
}
export async function CreateItem(params) {
    return formDataRequestFormat('/item/create', params);
}

export async function CreateTag(params) {
    return requestFormat('/tag/create', params);
}
export async function EditTag(params) {
    return requestFormat('/tag/edit', params);
}

export async function PostSupport(params) {
    return requestFormat('/support', params);
}

export async function PostSystemPush(params) {
    return requestFormat('/system-notification', params);
}

export async function PostPromotionPush(params) {
    return requestFormat('/promotion-notification', params);
}

export async function GetBusinessSections(entityId) {
    const res = await fetchWrapper(`/sections?entityId=${entityId}`);
    return res.json();
}

export async function PutBusinessSections(formData) {
    return formDataRequestFormat('/sections', formData, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function PutBusinessSectionItems(formData) {
    await formDataRequestFormat('/sections/items', formData, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function GetUserNotifications(page = 0, type = 'SYSTEM', maxCount = 10) {
    const data = await fetchWrapper(`/user-notifications?type=${type}&page=${page}&maxCount=${maxCount}`);
    return data.json();
}
export async function GetUserNotificationsTotal(type = 'LINK') {
    const data = await fetchWrapper(`/user-notifications-total?type=${type}`);
    return data.json();
}

export async function PutUserNotifications(formData) {
    await formDataRequestFormat('/user-notifications', formData, {
        method: 'PUT',
        body: formData,
    });
}
export async function GetPromoNotifications(businessId, page = 0, maxCount = 10) {
    const data = await fetchWrapper(
        `/promotion-notification?page=${page}&maxCount=${maxCount}&businessId=${businessId}`,
    );
    return data.json();
}

export async function GetItemDetails(businessId) {
    const data = await fetchWrapper(`/entity/${businessId}`, {
        headers: {
            Accept: 'application/json',
        },
    });
    return data.json();
}

export async function GetBusinessSectionItems({
    businessId,
    pageNum,
    maxCount,
    selectedSection,
    active,
}) {
    const requestPayload = {
        business_id: businessId,
        pageNum,
        maxCount,
    };
    if (active !== undefined) {
        requestPayload.active = active;
    }
    if (selectedSection) {
        requestPayload.sectionName = selectedSection;
    }

    const res = await fetchWrapper(`/business/items?${new URLSearchParams(requestPayload)}`);
    return res.json();
}

export async function GetUserSearch({ name }) {
    const res = await fetchWrapper(`/user-search?name=${name}`);
    return res.json();
}

export async function GetClaims(filters) {
    const res = await fetchWrapper(`/claims?${new URLSearchParams(filters)}`);
    return res.json();
}

export async function PutClaims(formData) {
    await formDataRequestFormat('/claims', formData, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function GetBusinessPermissions(businessId) {
    // business/permissions?businessid=entity_business/25561939
    // /entity-v2/permissions
    const res = await fetchWrapper(`/entity-v2/permissions?entityid=${businessId}`);
    return res.json();
}

export async function PostBusinessPermission(params) {
    return formDataRequestFormat('/entity-v2/permissions', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function PostAddBussinessPermission({ entityid, email }) {
    return PostBusinessPermission({
        entityid,
        email,
        type: 'ADD',
    });
}

export async function PostRemoveBussinessPermission({ entityid, userId }) {
    return PostBusinessPermission({
        entityid,
        userid: userId,
        type: 'REMOVE',
    });
}

export async function GetSuggestions({ page, sortField, sortDirection, stateFilter }) {
    const res = await fetchWrapper(
        `/suggest-fave?maxCount=10&page=${page}&sortFields=${sortField}&sortOrders=${sortDirection}&state=${stateFilter}`,
    );

    return res.json();
}

export async function BetaSignUp({
    firstName,
    lastName,
    email,
    phone,
    zipcode,
    referralBusiness,
    referralPerson,
    dob,
}) {
    return requestFormat('/beta-launch', {
        firstName,
        lastName,
        email,
        phone,
        zipcode,
        referralBusiness,
        referralPerson,
        dob,
    });
}

export async function GetBetaSignUps({ page }) {
    const res = await fetchWrapper(`/beta-launch?maxCount=10&page=${page}`);
    return res.json();
}

export async function PutBetaSignUps(formData) {
    return requestFormat('/beta-launch', formData, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function GetProfile() {
    const res = await fetchWrapper('/user-profile');
    return res.json();
}

export async function resetPassword({ identifier, code, newpassword }) {
    const params = {
        identifier: identifier?.toLowerCase(),
        code,
        newpassword,
    };
    return requestFormat('/reset-password', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function requestVerificationCode({ email, phone }) {
    const params = { email, phone };
    return requestFormat('/request-code', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function validateVerificationCode({ email, phone, code }) {
    const params = { email, phone, code };
    return requestFormat('/validate-code', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function postInquire(params) {
    return requestFormat('/inquire', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function postReferFriend(params) {
    return requestFormat('/refer-friend', params, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { 'Content-Type': 'application/json' },
    });
}

// Entity v2 endpoints
export async function getEntities({ searchText, approvalstatus, type = 'BUSINESS', page , cityLocation, filters, maxCount = 10}) {
    const pageNum = page ?? 0;
    // send sort approvalstatus
    let fetchUrl = `/admin-entity-v2?tabType=ADMIN&returnProperties=["ALL", "TOTALFAVES","FAVECOUNT"]&type=["${type}"]&page=${pageNum}&maxCount=${maxCount}`;
    if (searchText) {
        fetchUrl += `&searchQuery=${searchText}`;
    }
    if (approvalstatus && approvalstatus !== SEARCH_BUSINESSES_ANY_STATUS) {
        fetchUrl += `&approvalstatus=${approvalstatus}`;
    }
    if(cityLocation){
        fetchUrl += `&cityLocation=${cityLocation}`;
    }
    if(filters?.length > 0){
        fetchUrl += `&filters=${JSON.stringify(filters)}`;
    }
    const res = await fetchWrapper(fetchUrl);
    return res.json();
}

export async function getSectionEntities({ entityId, pageNum, maxCount, sectionName, active }) {
    const params = {
        entityid: entityId,

        pageNum,
        maxCount,
        active,
    };

    if (sectionName) {
        params.sectionName = sectionName;
    }

    const res = await fetchWrapper(
        `/entity-section-get-entities-v2?${new URLSearchParams(params)}`,
        // `/entity-section-get-entities-v2?entityid=${entityId}&pageNum=${pageNum}&maxCount=${maxCount}&active=${active}`,
    );
    return res.json();
}

export async function createEntity(params) {
    return formDataRequestFormat('/entity-v2', params);
}

export async function editEntity(params) {
    return formDataRequestFormat('/entity-v2', params, {
        method: 'PUT',
        body: params,
    });
}

export async function GetPeopleLinkRequests(query) {
    return fetchData({
        method: 'GET',
        path: '/link-people-entity-requests',
        query,
    });
}

export async function CreatePeopleLinkRequests(data) {
    return fetchData({
        method: 'POST',
        path: '/link-people-entity-requests',
        contentType: 'application/json',
        content: data,
        errorCodes: {
            DUPLICATE_REQUEST: {
                status: 400,
                data: {
                    status: 'error',
                    message: /^entity request already exists /i,
                },
            },
        },
    });
}

export async function UpdatePeopleLinkRequests(params) {
    return fetchData({
        method: 'PUT',
        path: '/link-people-entity-requests',
        contentType: 'application/x-www-form-urlencoded',
        content: params,
    });
}

// Entity v2 endpoints
export async function GetPublicEntities({ searchQuery, type }) {
    return fetchData({
        method: 'GET',
        path: '/entity-v2',
        contentType: 'application/json',
        query: {
            tabType: 'ADMIN',
            type: `["${type}"]`,
            searchQuery,
        },
    });
}

// New Tag v2 endpoints
export async function GetTier({
    startingTagId = 'ROOT',
    direction = 'DOWN',
    depth = 1,
    showHidden,
} = {}) {
    return fetchData({
        method: 'GET',
        path: '/tags-v2',
        contentType: 'application/json',
        query: {
            startingTagId,
            direction,
            depth,
            includeHidden: showHidden,
        },
    });
}

export async function UpdateTier(data) {
    const id = data.id ?? data._id;
    const content = { ...data, id };
    return fetchData({
        method: 'PUT',
        path: '/tags-v2',
        contentType: 'application/json',
        content,
    });
}

export async function CreateTier(data) {
    return fetchData({
        method: 'POST',
        path: '/tags-v2',
        contentType: 'application/json',
        content: data,
    });
}
