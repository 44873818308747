import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'baseline-ui/button';
import { ENTITY_TYPE_PEOPLE } from 'common/consts';
import { EntityPicker } from 'components/EntityPicker';
import { useFormikContext } from 'formik';
import { H3 } from 'baseline-ui/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { PUT_REQUESTTOLINK_START, RESET_REQUESTTOLINK_START } from 'redux/actions/RequestToLink';
import { GET_BUSINESS_PEOPLE_START } from 'redux/actions/OwnABussinessActions';
import { PEOPLELINK_STATUS_APPROVED } from '../../screens/BusinessForm/People';
import { ButtonArea } from '../ButtonArea';

/**
 * Initial values.
 */
export const initialValues = {
    /// Entity Picker.
    entitypicker_search: '',
    entitypicker_selected: '',
};

/**
 * People Link Admin Form.
 * @returns The component.
 */
export const PeopleLinkAdminForm = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [entity, setEntity] = useState(null);
    const businessForm = useSelector(businessFormSelector);
    const { status, code } = useSelector(({ RequestToLinkReducer }) => RequestToLinkReducer);
    const { resetForm } = useFormikContext();
    let statusMessage = '';
    let statusType = '';
    if (status === 'ERROR') {
        statusMessage = intl.formatMessage({
            id: `addBusiness.page.sections.requestToLink.error.${code ?? 'default'}`,
        });
        statusType = 'error';
    } else if (status === 'SUCCESS') {
        statusMessage = intl.formatMessage({
            id: `addBusiness.page.sections.requestToLink.success`,
        });
        statusType = 'success';
    }
    const disableSubmit = !!(!entity || status);
    const onSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        if (disableSubmit) {
            return;
        }
        const splitName = entity.name.split(' ');
        const payload = {
            approvalstatus: PEOPLELINK_STATUS_APPROVED,
            message: 'Added & Approved by Super Admin.',
            entityId: businessForm._id,
            requestingEntityId: entity._id,
            firstName: splitName[0],
            lastName: splitName.slice(1).join(' '),
            position: entity.position,
        };
        dispatch({ type: PUT_REQUESTTOLINK_START, payload });
        window.setTimeout(() => {
            // TODO: Remove this timeout.
            dispatch({ type: GET_BUSINESS_PEOPLE_START });
        }, 1000);
    };
    const onReset = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        setEntity(null);
        resetForm(initialValues);
        dispatch({ type: RESET_REQUESTTOLINK_START });
    };
    const hideControls = !!statusMessage;
    // TODO: Style status message. Currently being stored in data-status on the message element.
    return (
        <>
            {statusMessage && <H3 data-status={statusType}>{statusMessage}</H3>}
            <EntityPicker
                hidden={hideControls}
                label="addBusiness.page.sections.people.superadmin.addandapprove.label"
                type={ENTITY_TYPE_PEOPLE}
                onChange={(ent) => setEntity(ent)}
            />
            <ButtonArea>
                <Button type="button" skin="primary" onClick={onReset} hidden={!hideControls}>
                    <FormattedMessage id="addBusiness.page.sections.people.superadmin.addandapprove.acknowledge" />
                </Button>
                <Button
                    type="button"
                    skin="primary"
                    disabled={disableSubmit}
                    onClick={onSubmit}
                    hidden={hideControls}
                >
                    <FormattedMessage id="addBusiness.page.sections.people.superadmin.addandapprove.submit" />
                </Button>
            </ButtonArea>
        </>
    );
};
