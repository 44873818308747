import React, { useEffect } from 'react';
import { DefaultAlert } from 'baseline-ui/alert';
import { H5 } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { RESET_REQUESTTOLINK_START } from '../../redux/actions/RequestToLink';
import { initialValues, PeopleLinkAdminForm } from './PeopleLinkAdminForm';

/**
 * Change the text color to  dark text.
 * TODO: Fix this is the DefaultAlert/theme instead.
 * Without this, the text is a  light color on the light background.
 */
const AlertColorFix = styled.div`
    color: #000;
`;

/**
 * People Link Admin.
 * @returns The component.
 */
export const PeopleLinkAdmin = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: RESET_REQUESTTOLINK_START });
    }, []);
    return (
        <section>
            <DefaultAlert inProp alertType="primaryMuted" appear={false}>
                <AlertColorFix>
                    <H5 color="secondary">
                        <FormattedMessage id="addBusiness.page.sections.people.superadmin.addandapprove.title" />
                    </H5>
                    <Formik initialValues={initialValues}>
                        <PeopleLinkAdminForm />
                    </Formik>
                </AlertColorFix>
            </DefaultAlert>
        </section>
    );
};

export default PeopleLinkAdmin;
