import {
    GET_ENTITYPICKER_SUCCESS,
    RESET_ENTITYPICKER_START
} from "../actions/EntityPicker";

/**
 * Initial state.
 */
const initiaState = {
    searchQuery: "",
    type: "",
    entities: [],
}
  
/**
 * Entity Picker reducer.
 */
const EntityPickerReducer = (state = {}, action = {}) => {
   const { type, payload } = action;
    switch (type) {
        case RESET_ENTITYPICKER_START: {
            return { ...state, ...initiaState};
        }
        case GET_ENTITYPICKER_SUCCESS: {
            return { ...state, ...payload};
        }
        default: {
            return state;
        }
    }
};
export default EntityPickerReducer;
