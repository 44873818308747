import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { H1, H3, H4, P } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { ownABusinessSelector } from 'redux/selectors/ownABusinessSelectors';
import { PeopleLinkAdmin } from 'components/PeopleLink/PeopleLinkAdmin';
import {
    GET_BUSINESS_PEOPLE_START,
    POST_BUSINESS_PEOPLE_START,
} from '../../redux/actions/OwnABussinessActions';

// Request Statuses.
// TODO: Move these to a common file.
export const PEOPLELINK_STATUS_PENDING = 'PENDING';
export const PEOPLELINK_STATUS_APPROVED = 'APPROVED';
export const PEOPLELINK_STATUS_DENIED = 'DENIED';

/**
 * Photos Business Section.
 * @returns {JSX.Element} The component.
 */
const People = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_BUSINESS_PEOPLE_START,
        });
    }, []);
    const { peopleLinks } = useSelector(ownABusinessSelector);
    const isAdmin = useSelector(isAdminSelector);
    const { pending, accepted, denied } = useMemo(() => {
        const data = peopleLinks ?? [];
        const pending = data.filter((req) => req.approvalstatus === PEOPLELINK_STATUS_PENDING);
        const accepted = data.filter((req) => req.approvalstatus === PEOPLELINK_STATUS_APPROVED);
        const denied = data.filter((req) => req.approvalstatus === PEOPLELINK_STATUS_DENIED);
        return {
            pending,
            accepted,
            denied,
        };
    }, [peopleLinks]);
    const [openComment, setOpenComment] = useState(() => {
        return pending[0]?.id ?? accepted[0]?.id;
    });
    const addRequest = (id) => {
        dispatch({
            type: POST_BUSINESS_PEOPLE_START,
            payload: {
                entityLinkRequestsId: id,
                approvalstatus: PEOPLELINK_STATUS_APPROVED,
            },
        });
    };
    const removeRequest = (id) => {
        dispatch({
            type: POST_BUSINESS_PEOPLE_START,
            payload: {
                entityLinkRequestsId: id,
                approvalstatus: PEOPLELINK_STATUS_DENIED,
            },
        });
    };
    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.people.title" />
            </H1>
            <P>
                <FormattedMessage id="addBusiness.page.sections.people.subtitle" />
            </P>
            {isAdmin && <PeopleLinkAdmin />}
            <SubsectionContainer>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id="addBusiness.page.sections.people.pending.title" />
                    </SubsectionTitle>
                    {pending.length ? (
                        pending.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.firstName} ${req.lastName}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showAdd
                                        showExpand
                                        showRemove
                                        onExpand={() => setOpenComment(req._id)}
                                        onAdd={() => addRequest(req._id)}
                                        onRemove={() => removeRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id="addBusiness.page.sections.people.pending.empty" />
                            </small>
                        </P>
                    )}
                </Subsection>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id="addBusiness.page.sections.people.accepted.title" />
                    </SubsectionTitle>
                    {accepted.length ? (
                        accepted.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.firstName} ${req.lastName}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showExpand
                                        showRemove
                                        onExpand={() => setOpenComment(req._id)}
                                        onRemove={() => removeRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id="addBusiness.page.sections.people.accepted.empty" />
                            </small>
                        </P>
                    )}
                </Subsection>
                <Subsection>
                    <SubsectionTitle>
                        <FormattedMessage id="addBusiness.page.sections.people.denied.title" />
                    </SubsectionTitle>
                    {denied.length ? (
                        denied.map((req, idx) => {
                            const expand = (idx === 0 && !openComment) || openComment === req._id;
                            return (
                                <div key={req._id}>
                                    <LinkRequest
                                        id={req._id}
                                        name={`${req.firstName} ${req.lastName}`}
                                        date={new Date(req.created)}
                                        comment={req.message}
                                        expand={expand}
                                        showAdd
                                        showExpand
                                        onExpand={() => setOpenComment(req._id)}
                                        onAdd={() => addRequest(req._id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <P>
                            <small>
                                <FormattedMessage id="addBusiness.page.sections.people.denied.empty" />
                            </small>
                        </P>
                    )}
                </Subsection>
            </SubsectionContainer>
        </>
    );
};

/**
 * Subsection component.
 */
const Subsection = styled.section`
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 3rem;
`;

/**
 * Subsection Container component.
 */
const SubsectionContainer = styled.div``;

/**
 * Subsection Title component.
 */
const SubsectionTitle = styled(H3)``;
SubsectionTitle.defaultProps = {
    color: 'secondary',
};

/**
 * Request Button component.
 */
const RequestButton = styled(Button)`
    margin: 0.2rem;
`;
RequestButton.defaultProps = {
    type: 'button',
};

/**
 * LinkRequest component.
 */
const LinkRequest = ({
    name,
    date,
    comment,
    showAdd,
    showRemove,
    showExpand,
    expand,
    onExpand,
    onAdd,
    onRemove,
}) => {
    const text = comment.trim();
    const hidden = expand ? '' : 'hidden';
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                    <H4>{name ?? '-'}</H4>
                    <p>{date.toLocaleDateString()}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                    {showAdd && (
                        <RequestButton onClick={onAdd}>
                            <FormattedMessage id="addBusiness.page.sections.people.requests.approve.button.label" />
                        </RequestButton>
                    )}
                    {showRemove && (
                        <RequestButton onClick={onRemove}>
                            <FormattedMessage id="addBusiness.page.sections.people.requests.reject.button.label" />
                        </RequestButton>
                    )}
                    {showExpand && (
                        <RequestButton
                            btnType="link"
                            icon={expand ? 'chevron-down' : 'chevron-up'}
                            onClick={onExpand}
                        />
                    )}
                </div>
            </div>
            <p hidden={hidden}>
                {text || (
                    <small>
                        <FormattedMessage id="addBusiness.page.sections.people.requests.nocomment" />
                    </small>
                )}
            </p>
        </div>
    );
};
LinkRequest.propTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    comment: PropTypes.string,
    showAdd: PropTypes.bool,
    showRemove: PropTypes.bool,
    showExpand: PropTypes.bool,
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
};
LinkRequest.defaultProps = {
    comment: '',
    showAdd: false,
    showRemove: false,
    showExpand: false,
    expand: false,
    onExpand: () => {},
};

/**
 * Export People as the default.
 */
export default People;
