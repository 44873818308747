import { put, takeEvery } from "redux-saga/effects";
import {
    PUT_REQUESTTOLINK_ERROR,
    PUT_REQUESTTOLINK_START,
    PUT_REQUESTTOLINK_PROCESS,
    PUT_REQUESTTOLINK_SUCCESS,
} from "../actions/RequestToLink";
import genericSagaHandler from "./CommonSaga";
import { CreatePeopleLinkRequests, UpdatePeopleLinkRequests } from '../../api';

function* putRequestToLinkSaga({ payload }) {
    yield genericSagaHandler(PUT_REQUESTTOLINK_PROCESS, function* () {
        const [results, err] = yield CreatePeopleLinkRequests({
            "entityIdRequested": payload.entityId,
            "requestingEntityId": payload.requestingEntityId,
            "firstName": payload.firstName,
            "lastName": payload.lastName,
            "approvalstatus": "PENDING",
            "message": payload.message,
            "position": payload.position
        });
        if(err) {
            yield put({
                type: PUT_REQUESTTOLINK_ERROR,
                payload: {
                    requestId: '',
                    status: 'ERROR',
                    code: err.code,
                },
            });
            return;         
        }
        const requestId = results._id;
        if(payload.approvalstatus && payload.approvalstatus !== 'PENDING') {
            // If a payload is provided, then update the payload
            const [_results, err] = yield UpdatePeopleLinkRequests({
                entityLinkRequestsId: requestId,
                approvalstatus: payload.approvalstatus,
            });
            if(err) {
                yield put({
                    type: PUT_REQUESTTOLINK_ERROR,
                    payload: {
                        requestId: '',
                        status: 'ERROR',
                        code: err.code,
                    },
                });
                return;
            }        
        }
        yield put({
            type: PUT_REQUESTTOLINK_SUCCESS,
            payload: {
                requestId,
            },
        });        
    });
}

export default function* RequestToLinkSaga() {
    yield takeEvery(PUT_REQUESTTOLINK_START, putRequestToLinkSaga);
}
