import React from 'react';
import { Container, Box, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const events = [
    { id: 1, name: 'Event 1', date: '2023-07-01', location: 'Location 1' },
    { id: 2, name: 'Event 2', date: '2023-07-02', location: 'Location 2' },
    // Add more events as needed
];

const EventList = () => {
    const handleDelete = (id) => {
        console.log(`Delete event with id: ${id}`);
        // Implement delete functionality here
    };

    return (
        <Container>
            <Box sx={{ p: 2 }}>
                <List>
                    {events.map((event) => (
                        <React.Fragment key={event.id}>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDelete(event.id)}
                                        sx={{ color: '#f15a24' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={event.name}
                                    secondary={`${event.date} - ${event.location}`}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Container>
    );
};

export default EventList;
