import React, { useContext, useEffect, useMemo, useState } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import { ThemeContext } from 'styled-components';
import PageHeader from 'components/pageLayout/PageHeader';
import { useIntl } from 'react-intl';
import FavePageAsideMyBusiness from 'components/favePageAside/FavePageAsideMyBusiness';
import useBusinessSectionsNavigation from 'utils/useBusinessSectionsNavigation';
import FavePageMainMyBusiness from 'components/favePageMain/FavePageMainMyBusiness';
import NavigationFooter from 'components/NavigationFooter';
import { ModalContext } from 'baseline-ui/modal';
import useProcessLoader from 'utils/useProcessLoader';
import {
    BUSINESS_APPROVAL_STATUS_APPROVED,
    BUSINESS_APPROVAL_STATUS_DRAFT,
    BUSINESS_APPROVAL_STATUS_PENDING,
    BUSINESS_STATUS_AS_DRAFT,
    EDIT_BUSINESS_ACTION_SAVE,
    EDIT_BUSINESS_ACTION_SUBMIT,
    EDIT_BUSINESS_ACTION_UPDATE,
} from 'common/consts';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessError from 'utils/useProcessError';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { get } from 'lodash';
import AdminBusinessStatus from 'components/favePageMain/admin/AdminBusinessStatus';
import useScrollToTop from 'utils/useScrollToTop';
import useEditBusinessValid from 'utils/useEditBusinessValid';
import useAddBusinessValid from 'utils/useAddBusinessValid';
import useBlockerOnUnsavedChangesBusiness from 'utils/useBlockerOnUnsavedChangesBusiness';
import {
    GET_SECTIONS_RESET,
    GET_SECTIONS_START,
    GET_SECTION_PRODUCTS_START,
} from 'redux/actions/SectionActions';
import {
    RESET_PRODUCT_TO_EDIT_START,
    SAVE_PRODUCTS_BULK_PROCESS,
} from 'redux/actions/EditProductActions';
import {
    GET_BUSINESS_FORM_START,
    POST_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    RESET_BUSINESS_FORM_START,
} from '../redux/actions/BusinessFormActions';
// import BusinessForm from './BusinessForm/BusinessForm';
import CommonWrapper from './CommonWrapper';
import { isDraftlikeState } from 'utils/businessUtils';
// import BusinessAccessForm from './BusinessForm/BusinessAccessForm';
// import ProductsForm from './BusinessForm/ProductsForm';
import ModalEditBusinessConfirmSubmit from './BusinessForm/ModalEditBusinessConfirmSubmit';
import ModalPreviewBusiness from './BusinessForm/ModalPreviewBusiness';
import ModalEditBusinessConfirmUpdate from './BusinessForm/ModalEditBusinessConfirmUpdate';
import ModalSaveBusinessConfirmSubmit from './BusinessForm/ModalSaveBusinessConfirmSubmit';
import ModalUnsavedChangesSection from './BusinessForm/ModalUnsavedChangesSection';


const EditBusiness = () => {
    const { id } = useParams();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { state } = useLocation();
    let url = new URL(window.location);
    const navigate = useNavigate();

    const processCompleteWithFeedbackFromNavigation = get(
        state,
        'processCompleteWithFeedback',
        true,
    );
    const theme = useContext(ThemeContext);
    const { showModal } = useContext(ModalContext);
    const {
        name,
        approval_status: approvalStatus,
        hasUnsavedChanges,
    } = useSelector(businessFormSelector);

    const [loadingByStatus, setLoadingByStatus] = useState();

    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
        withOverlay: true,
    });

    useProcessComplete({
        processNames: [POST_BUSINESS_FORM_PROCESS, SAVE_PRODUCTS_BULK_PROCESS],
        withFeedback: processCompleteWithFeedbackFromNavigation,
    });
    useProcessError({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: true });

    const isSubmitValid = useEditBusinessValid();
    const isSaveSubmitValid = useAddBusinessValid();

    useEffect(() => {
        if (id) {
            dispatch({ type: GET_BUSINESS_FORM_START, payload: id });
            dispatch({
                type: GET_SECTIONS_START,
                payload: { entityId: id },
            });
            dispatch({
                type: GET_SECTION_PRODUCTS_START,
                payload: {
                    businessId: id,
                },
            });
        }
    }, [id]);

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_SECTIONS_RESET,
            });
            dispatch({
                type: RESET_PRODUCT_TO_EDIT_START,
            });
            dispatch({
                type: RESET_BUSINESS_FORM_START,
            });
        };
    }, []);

    const handleOnClickSubmitCallback = (submitForApproval) => {
        if (
            !isSubmitValid &&
            (approvalStatus === BUSINESS_APPROVAL_STATUS_PENDING ||
                approvalStatus === BUSINESS_APPROVAL_STATUS_APPROVED)
        ) {
            return showModal(ModalEditBusinessConfirmUpdate);
        }
        if (!isSubmitValid) {
            return showModal(ModalEditBusinessConfirmSubmit);
        }
        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                submitForApproval,
            },
        });
    };

    const handleOnClickSaveCallback = (submitForApproval) => {
        if (!isSaveSubmitValid) {
            return showModal(ModalSaveBusinessConfirmSubmit);
        }
        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                submitForApproval,
            },
        });
    };

    const handleOnClickSubmitForApprovalCallback = () => {
        handleOnClickSubmitCallback(true);
    };

    const {
        handleBackNavigation,
        handleNextNavigation,
        activeView,
        setActiveView,
        lastSectionIndex,
    } = useBusinessSectionsNavigation(hasUnsavedChanges, handleOnClickSaveCallback);

    useScrollToTop(activeView);

    useBlockerOnUnsavedChangesBusiness(hasUnsavedChanges, handleOnClickSaveCallback);

    const { sectionId, sectionIndex } = activeView;

    const renderMainFooterActions = useMemo(() => {
        if (!approvalStatus) {
            return null;
        }
        const mainFooterActions = [
            {
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.preview.button.label',
                }),
                skin: 'primary',
                accessibilityLabel: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.preview.button.label',
                }),
                onClick: () =>
                    showModal(ModalPreviewBusiness, {
                        addToSection: (addSectionId) => setActiveView({ sectionId: addSectionId }),
                    }),
            },
        ];
        if (isDraftlikeState(approvalStatus)) {
            mainFooterActions.push({
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.save.button.label',
                }),
                skin: 'secondary',
                onClick: () => {
                    handleOnClickSaveCallback();
                    setLoadingByStatus(EDIT_BUSINESS_ACTION_SAVE);
                },
                loading: isLoading && loadingByStatus === EDIT_BUSINESS_ACTION_SAVE,
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.saving.button.label',
                }),
            });
        }
        if (isDraftlikeState(approvalStatus)) {
            mainFooterActions.push({
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.submit.button.label',
                }),
                skin: 'secondary',
                onClick: () =>
                    showModal(ModalEditBusinessConfirmSubmit, {
                        onClickSubmitCallback: () => {
                            handleOnClickSubmitForApprovalCallback();
                            setLoadingByStatus(EDIT_BUSINESS_ACTION_SUBMIT);
                        },
                    }),
                loading: isLoading && loadingByStatus === EDIT_BUSINESS_ACTION_SUBMIT,
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.submitting.button.label',
                }),
            });
        }
        if (
            approvalStatus === BUSINESS_APPROVAL_STATUS_PENDING ||
            approvalStatus === BUSINESS_APPROVAL_STATUS_APPROVED
        ) {
            mainFooterActions.push({
                label: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.update.button.label',
                }),
                skin: 'secondary',
                onClick: () => {
                    handleOnClickSubmitCallback();
                    setLoadingByStatus(EDIT_BUSINESS_ACTION_UPDATE);
                },
                loading: isLoading && loadingByStatus === EDIT_BUSINESS_ACTION_UPDATE,
                loadingMessage: intl.formatMessage({
                    id: 'addBusiness.page.headerNavigation.updating.button.label',
                }),
            });
        }

        return mainFooterActions;
    }, [approvalStatus, isLoading, isSubmitValid, isSaveSubmitValid]);

    return (
        <CommonWrapper>
            <AdminLeftAsideTemplate
                bgStart={theme.site.admin.bgStart}
                bgEnd={theme.site.admin.bgEnd}
                banner={<PageHeader title={name} />}
                aside={
                    <FavePageAsideMyBusiness
                        currentSectionId={sectionId}
                        onClick={(currentSectionId, currentSectionIndex) => {
                            const handleSetActiveView = () =>
                                setActiveView({
                                    sectionId: currentSectionId,
                                    sectionIndex: currentSectionIndex,
                                });
                            if (hasUnsavedChanges) {
                                return showModal(ModalUnsavedChangesSection, {
                                    onSaveCallback: handleOnClickSaveCallback,
                                    onCompleteCallback: () => handleSetActiveView(),
                                });
                            }
                            navigate(`${url.pathname}?section=${currentSectionId}`);
                            return handleSetActiveView();
                        }}
                    />
                }
                main={
                    <>
                        <AdminBusinessStatus />
                        <FavePageMainMyBusiness
                            currentSectionId={sectionId}
                            handleBackNavigation={handleBackNavigation}
                            disableBackNavigation={sectionIndex === 0}
                            handleNextNavigation={handleNextNavigation}
                            disableNextNavigation={sectionIndex === lastSectionIndex}
                        />
                    </>
                }
                mainFooter={
                    <NavigationFooter
                        handleBackNavigation={handleBackNavigation}
                        disableBackNavigation={sectionIndex === 0}
                        handleNextNavigation={handleNextNavigation}
                        disableNextNavigation={sectionIndex === lastSectionIndex}
                        actions={renderMainFooterActions}
                    />
                }
            />
        </CommonWrapper>
    );
};

export default EditBusiness;
