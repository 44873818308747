import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import {useDispatch} from 'react-redux';
import { GET_BUSINESS_SUBCATEGORY_FILTERS_START } from '../../redux/actions/CategoriesActions';
import {MenuItem, Button, Box, Checkbox, ListItemText} from '@mui/material';
import {H3} from "baseline-ui/typography";
import CategoriesSelect from "../../components/CategoriesSelect";
import PropTypes from "prop-types";
import categoryAndFiltersUtils from 'utils/categoriesAndFiltersUtils';

const CategoriesForm = ({
    onSubmit,
    categories,
    subcategories,
    selectedCategories,
    handleCategoryChange,
    handleSubcategoryChange,
    categoryId,
    subcategoryId,
    subcategoryFilters,
}) => {
    const dispatch = useDispatch();
    const setFieldValueRef = useRef(null);
    const [selectedFilters, setSelectedFilters] = useState({});
    const initialValues = {
        category: '',
        subcategory: '',
        filter: {},
    };

    useEffect(() => {
        setFieldValueRef.current('filter', {});
        const initialSelectedFilters = categoryAndFiltersUtils.initializeSelectedFilters(selectedCategories);
        setSelectedFilters(initialSelectedFilters);
    }, [selectedCategories]);

    useEffect(() => {
        if (categoryId && subcategories.length > 0) {
            if (subcategoryId) {
                if (setFieldValueRef.current) {
                    setFieldValueRef.current('category', categoryId);
                    setFieldValueRef.current('subcategory', subcategoryId);
                    setFieldValueRef.current('filter', {});
                    dispatch({
                        type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                        payload: { subcategoryId, categoryId },
                    });
                }
            } else if (!subcategoryId) {
                if (setFieldValueRef.current) {
                    setFieldValueRef.current('category', categoryId);
                    setFieldValueRef.current('subcategory', '');
                    setFieldValueRef.current('filter', {});
                }
            }
        } else if (categoryId && subcategoryFilters.length > 0) {
            if (setFieldValueRef.current) {
                setFieldValueRef.current('category', categoryId);
                setFieldValueRef.current('subcategory', '');
                setFieldValueRef.current('filter', {});
            }
        }
    }, [categoryId, subcategoryId, subcategories, subcategoryFilters]);


    const handleCheckboxToggle = (headerId, filterId) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [headerId]: {
                ...(prevFilters[headerId] || {}),
                [filterId]: !(prevFilters[headerId]?.[filterId] || false)
            }
        }));
    };
    const isChecked = (headerId, filterId) => {
        return selectedFilters[headerId]?.[filterId] || false;
    };
    const handleSubmit = (values) => {
        values.filter = selectedFilters;
        onSubmit(values);
    };
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => {
                setFieldValueRef.current = setFieldValue;
                return (
                    <Form>
                        <Box mt={2} mb={3}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn={{ xs: 'span 12', lg: 'span 6' }} >
                                    <H3 color="secondary">
                                        <FormattedMessage id="addBusiness.page.sections.categories.list.category.label" />
                                    </H3>
                                    <CategoriesSelect
                                        name="category"
                                        fullWidth
                                        displayEmpty
                                        value={values.category}
                                        onChange={(e) => handleCategoryChange(e, setFieldValue)}
                                    >
                                        <MenuItem value="">
                                            <em>Select a category</em>
                                        </MenuItem>
                                        {categories?.map((category) => (
                                            <MenuItem key={category._id} value={category._id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </CategoriesSelect>
                                </Box>
                            </Box>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                {subcategories && subcategories.length > 0 && (
                                    <Box gridColumn={{ xs: 'span 12', lg: 'span 6' }}>
                                        <H3 color="secondary">
                                            <FormattedMessage id="addBusiness.page.sections.categories.subcategory.label" />
                                        </H3>
                                        <CategoriesSelect
                                            name="subcategory"
                                            fullWidth
                                            displayEmpty
                                            value={values.subcategory}
                                            onChange={(e) =>
                                                handleSubcategoryChange(e, setFieldValue)
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>Select a subcategory</em>
                                            </MenuItem>
                                            {subcategories.map((subcategory) => (
                                                <MenuItem
                                                    key={subcategory._id}
                                                    value={subcategory._id}
                                                >
                                                    {subcategory.name}
                                                </MenuItem>
                                            ))}
                                        </CategoriesSelect>
                                    </Box>
                                )}
                            </Box>
                                {subcategoryFilters && subcategoryFilters.length > 0 && (
                                   <Box mt={2} width={'100%'}>
                                       <H3 color="secondary">
                                            <FormattedMessage id="addBusiness.page.sections.categories.filters.label" />
                                     </H3>
                                       <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                        {subcategoryFilters.map(
                                            (header, index) => {
                                                const selectedValue = categoryAndFiltersUtils.processFilterValue(values, index, header, selectedFilters);
                                                const availableOptions = categoryAndFiltersUtils.getAvailableOptions(header);
                                                return (
                                                  header.filters.length > 0 && (
                                                    <Box gridColumn={{ xs: 'span 12', md: 'span 6' }} key={header._id}>
                                                        <CategoriesSelect
                                                            name={`filter[${index}]`}
                                                            fullWidth
                                                            displayEmpty
                                                            value={selectedValue}
                                                            multiple
                                                            renderValue={(selected) => (
                                                                <div>
                                                                    {Array.isArray(selected)
                                                                        ? selected.map(value => {
                                                                            const selectedItem = availableOptions.find(option => option.value === value);
                                                                            return selectedItem ? selectedItem.label : '';
                                                                        }).join(', ')
                                                                        : selected.label
                                                                    }
                                                                </div>
                                                            )}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select {header.name}</em>
                                                            </MenuItem>
                                                            {header.filters.map((filter) => {
                                                                return (
                                                                <MenuItem
                                                                    key={filter._id}
                                                                    value={`${header._id},${filter._id}`}
                                                                    onClick={() => handleCheckboxToggle(header._id, filter._id)}
                                                                >
                                                                    <Checkbox
                                                                        checked={isChecked(header._id, filter._id)}
                                                                        />
                                                                    <ListItemText primary={filter.name} />
                                                                </MenuItem>
                                                                )
                                                            })}
                                                        </CategoriesSelect>
                                                    </Box>
                                                ));
                                            }
                                        )}
                                     </Box>
                                   </Box>
                                )}
                            </Box>

                        <Button
                            type="button"
                            variant="contained"
                            color="warning"
                            sx={{ borderRadius: '25px', marginBottom: '16px' }}
                            onClick={() => handleSubmit(values)}
                        >
                            <FormattedMessage id="addBusiness.page.sections.categories.addCategory.button.label" />
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
CategoriesForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    subcategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ),
    handleCategoryChange: PropTypes.func.isRequired,
    handleSubcategoryChange: PropTypes.func.isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryFilters: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ),
};

CategoriesForm.defaultProps = {
    subcategories: [],
    selectedCategories: [],
    categoryId: null,
    subcategoryId: null,
    subcategoryFilters: [],
};

export default CategoriesForm;
