import { createTheme } from '@mui/material/styles';
import siteBaseTheme from "./siteBaseTheme";
import siteTypographyTheme from "./siteTypographyTheme";
import {formTheme} from "../baseline-ui/form";
import { rem } from 'polished';

const muiTheme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontSize: formTheme.form.input.fontSize,
                    padding: rem(16),
                    borderRadius: formTheme.form.input.borderRadius,
                    borderColor: siteBaseTheme.base.grays.grayLightest,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    height: formTheme.form.input.height,
                    backgroundColor: siteBaseTheme.base.colors.light,
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    },
                    '&:hover, &.Mui-focused': {
                        borderColor: siteBaseTheme.base.colors.primary,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                    },
                },
                select: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontWeight: '400',
                    fontSize: formTheme.form.input.fontSize,
                    '&:focus': {
                        backgroundColor: siteBaseTheme.base.colors.light,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.selectLabelItem':{
                        fontFamily: siteTypographyTheme.typography.base.fontFamily,
                        fontSize: formTheme.form.label.fontSizeFocus,
                        paddingLeft: rem(16),
                        paddingTop: rem(4),
                        paddingBottom: rem(4),
                        textTransform: 'capitalize',
                        position: 'absolute',
                        zIndex: '1',
                    }
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: siteTypographyTheme.typography.base.fontFamily,
                    fontWeight: '400',
                    fontSize: formTheme.form.input.fontSize,
                    '&.stickyMenuItem': {
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: siteBaseTheme.base.colors.light,
                        zIndex: 1,
                    },
                    '& .MuiButton-root': {
                        color:  siteBaseTheme.base.colors.primary,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: siteBaseTheme.base.colors.secondary,
                    '&.Mui-checked': {
                        color: siteBaseTheme.base.colors.primary,
                    },
                },
            },
        },
    },
});

export default muiTheme;