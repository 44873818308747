import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
    TextField,
    Grid,
    Box,
    Container,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { H1, P, H3 } from 'baseline-ui/typography';
import * as yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { ENTITY_TYPE_PEOPLE, ENTITY_TYPE_BUSSINESS } from 'common/consts';
import EventEntityPicker from 'components/EntityPicker/EventEntityPicker';
import { EntityPickerDetails } from 'components/EntityPicker/EntityPickerDetails';
import { Button as BaselineButton } from 'baseline-ui/button';
import { EntityPicker } from 'components/EntityPicker';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#f15a24',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f15a24',
        },
    },
    button: {
        borderRadius: '25px',
        backgroundColor: '#f15a24',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d14c20',
        },
        marginTop: 6,
    },
    radioButton: {
        '&.Mui-checked': {
            color: '#f15a24',
        },
    },
}));

const validationSchema = yup.object({
    eventName: yup.string().required('Event Name is required'),
    location: yup.string().required('Location is required'),
    date: yup.string().required('Date is required'),
    time: yup.string().required('Time is required'),
    linkWebsite: yup.string().url('Enter a valid URL'),
    linkVenue: yup.string(),
    linkHeadlinePerson: yup.string(),
    linkAdditionalPeople: yup.array(),
    eventDescription: yup.string(),
    locationName: yup.string().when('locationOption', {
        is: 'setLocation',
        then: yup.string().required('Location Name is required'),
    }),
    address: yup.string().when('locationOption', {
        is: 'setLocation',
        then: yup.string().required('Address is required'),
    }),
    city: yup.string().when('locationOption', {
        is: 'setLocation',
        then: yup.string().required('City is required'),
    }),
    zipCode: yup.string().when('locationOption', {
        is: 'setLocation',
        then: yup.string().required('ZIP Code is required'),
    }),
});

const EventForm = () => {
    const classes = useStyles();
    const intl = useIntl();

    const [selectedEntities, setSelectedEntities] = useState([]);
    const [locationOption, setLocationOption] = useState('thisBusiness');
    const [eventType, setEventType] = useState('Performer');
    const [entity, setEntity] = useState(null);
    const [headlinePerformer, setHeadlinePerformer] = useState([]);

    const handleEntitySelect = (entity) => {
        if (entity) {
            setSelectedEntities([...selectedEntities, entity]);
        }
    };

    const handlePerformerSelect = (entity) => {
        if (entity) {
            setHeadlinePerformer([...headlinePerformer, entity]);
        }
    };

    const handlePerformerRemove = (entityId) => {
        setHeadlinePerformer(headlinePerformer.filter((entity) => entity._id !== entityId));
    };

    const handleEntityRemove = (entityId) => {
        setSelectedEntities(selectedEntities.filter((entity) => entity._id !== entityId));
    };

    return (
        <Container>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.events.title" />
            </H1>
            <Formik
                initialValues={{
                    eventName: '',
                    location: '',
                    date: '',
                    time: '',
                    website: '',
                    venue: '',
                    headlinePerson: [],
                    additionalPeople: [],
                    description: '',
                    formattedAddress: '',
                    additionalPerson: '',
                    locationName: '',
                    address: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    searchBusiness: '',
                    searchPeople: '',
                    customEventType: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    console.log(values);
                    resetForm();
                }}
            >
                {({ values, setFieldValue }) => {
                    const dynamicTitle = eventType === 'Other' ? values.customEventType : eventType;
                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        as={TextField}
                                        name="eventName"
                                        label={intl.formatMessage({
                                            id: 'addBusiness.page.sections.events.label.name',
                                        })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.root}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        as={TextField}
                                        name="date"
                                        label={intl.formatMessage({
                                            id: 'addBusiness.page.sections.events.label.date',
                                        })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.root}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        as={TextField}
                                        name="time"
                                        label={intl.formatMessage({
                                            id: 'addBusiness.page.sections.events.label.time',
                                        })}
                                        type="time"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.root}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <H3 color="secondary">
                                        <FormattedMessage id="addBusiness.page.sections.events.title.description" />
                                    </H3>
                                    <Field
                                        as={TextField}
                                        name="eventDescription"
                                        label={intl.formatMessage({
                                            id: 'addBusiness.page.sections.events.label.description',
                                        })}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        className={classes.root}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <H3 color="secondary">
                                            <FormattedMessage id="addBusiness.page.sections.events.title.location" />
                                        </H3>
                                        <RadioGroup
                                            row
                                            aria-label="location"
                                            name="location"
                                            value={locationOption}
                                            onChange={(e) => setLocationOption(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="thisBusiness"
                                                control={<Radio className={classes.radioButton} />}
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.this.business',
                                                })}
                                            />
                                            <FormControlLabel
                                                value="linkBusiness"
                                                control={<Radio className={classes.radioButton} />}
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.link.business',
                                                })}
                                            />
                                            <FormControlLabel
                                                value="setLocation"
                                                control={<Radio className={classes.radioButton} />}
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.set.location',
                                                })}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {locationOption === 'linkBusiness' && (
                                    <Grid item xs={12}>
                                        <EntityPicker
                                            label={intl.formatMessage({
                                                id: 'addBusiness.page.sections.events.label.link.business.placeholder',
                                            })}
                                            name="searchBusiness"
                                            type={ENTITY_TYPE_BUSSINESS}
                                            onChange={(ent) => setEntity(ent)}
                                        />
                                        <Box mt={2}>
                                            {selectedEntities.map((entity, index) => (
                                                <Box
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={1}
                                                >
                                                    <EntityPickerDetails entity={entity} />
                                                    <BaselineButton
                                                        skin="primary"
                                                        icon="x"
                                                        btnType="outlined"
                                                        type="button"
                                                        onClick={() =>
                                                            handleEntityRemove(entity._id)
                                                        }
                                                    >
                                                        <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                                    </BaselineButton>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                                {locationOption === 'setLocation' && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                as={TextField}
                                                name="locationName"
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.location.name',
                                                })}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.root}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                as={TextField}
                                                name="address"
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.location.address',
                                                })}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.root}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                as={TextField}
                                                name="city"
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.location.city',
                                                })}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.root}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                as={TextField}
                                                name="state"
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.location.state',
                                                })}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.root}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                as={TextField}
                                                name="zipCode"
                                                label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.events.label.location.zip',
                                                })}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.root}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <H3 color="secondary">
                                            <FormattedMessage id="addBusiness.page.sections.events.link.performer.title" />
                                        </H3>
                                        <RadioGroup
                                            row
                                            aria-label="eventType"
                                            name="eventType"
                                            value={eventType}
                                            onChange={(e) => setEventType(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Performer"
                                                control={<Radio className={classes.radioButton} />}
                                                label="Performer"
                                            />
                                            <FormControlLabel
                                                value="Host"
                                                control={<Radio className={classes.radioButton} />}
                                                label="Host"
                                            />
                                            <FormControlLabel
                                                value="Sponsor"
                                                control={<Radio className={classes.radioButton} />}
                                                label="Sponsor"
                                            />
                                            <FormControlLabel
                                                value="Other"
                                                control={<Radio className={classes.radioButton} />}
                                                label="Other"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {eventType === 'Other' && (
                                        <Field
                                            as={TextField}
                                            name="customEventType"
                                            label="Custom Event Type"
                                            variant="outlined"
                                            fullWidth
                                            className={classes.root}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <H3 color="secondary">
                                        <FormattedMessage
                                            id="addBusiness.page.sections.events.link.performer.label"
                                            values={{ type: dynamicTitle }}
                                        />
                                    </H3>
                                    <P>
                                        <FormattedMessage
                                            id="addBusiness.page.sections.events.link.performer.placeholder"
                                            values={{ type: dynamicTitle }}
                                        />
                                    </P>
                                    <EventEntityPicker
                                        label={intl.formatMessage(
                                            {
                                                id: 'addBusiness.page.sections.events.link.performer.additional.placeholder',
                                            },
                                            {
                                                type: dynamicTitle,
                                            },
                                        )}
                                        name="searchHeadlinePerformer"
                                        type={ENTITY_TYPE_PEOPLE}
                                        onEntitySelect={handlePerformerSelect}
                                    />
                                    <Box mt={2}>
                                        {headlinePerformer.map((entity, index) => (
                                            <Box
                                                key={index}
                                                display="flex"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <EntityPickerDetails entity={entity} />
                                                <BaselineButton
                                                    skin="primary"
                                                    icon="x"
                                                    btnType="outlined"
                                                    type="button"
                                                    onClick={() =>
                                                        handlePerformerRemove(entity._id)
                                                    }
                                                >
                                                    <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                                </BaselineButton>
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <H3 color="secondary">
                                        <FormattedMessage
                                            id="addBusiness.page.sections.events.link.performer.additional"
                                            values={{ type: dynamicTitle }}
                                        />
                                    </H3>
                                    <P>
                                        <FormattedMessage
                                            id="addBusiness.page.sections.events.link.performer.placeholder"
                                            values={{ type: dynamicTitle }}
                                        />
                                    </P>
                                    <EventEntityPicker
                                        label={intl.formatMessage(
                                            {
                                                id: 'addBusiness.page.sections.events.link.performer.additional.placeholder',
                                            },
                                            {
                                                type: dynamicTitle,
                                            },
                                        )}
                                        type={ENTITY_TYPE_PEOPLE}
                                        onEntitySelect={handleEntitySelect}
                                    />
                                    <Box mt={2}>
                                        {selectedEntities.map((entity, index) => (
                                            <Box
                                                key={index}
                                                display="flex"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <EntityPickerDetails entity={entity} />
                                                <BaselineButton
                                                    skin="primary"
                                                    icon="x"
                                                    btnType="outlined"
                                                    type="button"
                                                    onClick={() => handleEntityRemove(entity._id)}
                                                >
                                                    <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                                                </BaselineButton>
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default EventForm;
