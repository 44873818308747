export const RESET_BUSINESS_START = "RESET_BUSINESS_START";
export const RESET_PRODUCTS_START = "RESET_PRODUCTS_START";

export const GET_BUSINESS_PROCESS = "GET_BUSINESS_PROCESS";
export const GET_BUSINESS_START = "GET_BUSINESS_START";
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";

export const SET_PRODUCT_FILTER_PROCESS = "SET_PRODUCT_FILTER_PROCESS";
export const SET_PRODUCT_FILTER_START = "SET_PRODUCT_FILTER_START";
export const SET_PRODUCT_FILTER_SUCCESS = "SET_PRODUCT_FILTER_SUCCESS";

export const GET_BUSINESS_PRODUCTS_PROCESS = "GET_BUSINESS_PRODUCTS_PROCESS";
export const GET_BUSINESS_PRODUCTS_START = "GET_BUSINESS_PRODUCTS_START";
export const GET_BUSINESS_PRODUCTS_SUCCESS = "GET_BUSINESS_PRODUCTS_SUCCESS";

export const POST_BUSINESS_PROCESS = "POST_BUSINESS_PROCESS";
export const POST_BUSINESS_PROCESS_RESET = "POST_BUSINESS_PROCESS_RESET";
export const POST_BUSINESS_START = "POST_BUSINESS_START";
export const POST_BUSINESS_SUCCESS = "POST_BUSINESS_SUCCESS";

export const GET_FEATURE_MAPPING_PROCESS = "GET_FEATURE_MAPPING_PROCESS";
export const GET_FEATURE_MAPPING_START = "GET_FEATURE_MAPPING_START";
export const GET_FEATURE_MAPPING_SUCCESS = "GET_FEATURE_MAPPING_SUCCESS";

export const ADD_BUSINESS_PRODUCT_PROCESS = "ADD_BUSINESS_PRODUCT_PROCESS";
export const ADD_BUSINESS_PRODUCT_START = "ADD_BUSINESS_PRODUCT_START";
export const ADD_BUSINESS_PRODUCT_SUCCESS = "ADD_BUSINESS_PRODUCT_SUCCESS";

export const GET_BUSINESS_PEOPLE_PROCESS = "GET_BUSINESS_PEOPLE_PROCESS";
export const GET_BUSINESS_PEOPLE_START = "GET_BUSINESS_PEOPLE_START";
export const GET_BUSINESS_PEOPLE_SUCCESS = "GET_BUSINESS_PEOPLE_SUCCESS";

export const POST_BUSINESS_PEOPLE_PROCESS = "POST_BUSINESS_PEOPLE_PROCESS";
export const POST_BUSINESS_PEOPLE_START = "POST_BUSINESS_PEOPLE_START";
export const POST_BUSINESS_PEOPLE_SUCCESS = "POST_BUSINESS_PEOPLE_SUCCESS";
