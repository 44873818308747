import React, { useContext, useEffect } from 'react';
import { H1, P } from 'baseline-ui/typography';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import formAddBusinessAccessRightsSchema from 'schema/formAddBusinessAccessRightsSchema';
import {
    ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
    ADD_BUSINESS_ACCESS_RIGHTS_USER_START,
    GET_BUSINESS_ACCESS_RIGHTS_START,
    REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
} from 'redux/actions/BusinessAccessRightsActions';
import { businessIdSelector } from 'redux/selectors/businessFormSelectors';
import AccessRightsUsersList from 'components/AccessRightsUsersList';
import { addedUsersBusinessAccessRightsSelector } from 'redux/selectors/businessAccessRightsSelectors';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessError from 'utils/useProcessError';
import { ModalContext } from 'baseline-ui/modal';
import AccessRightsForm from './AccessRightsForm';

const AccessRights = () => {
    const dispatch = useDispatch();
    const businessId = useSelector(businessIdSelector);
    const users = useSelector(addedUsersBusinessAccessRightsSelector);
    const { showModal } = useContext(ModalContext);

    useProcessComplete({
        processNames: [
            ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
            REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
        ],
        withFeedback: true,
    });

    useProcessError({
        processNames: [
            ADD_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
            REMOVE_BUSINESS_ACCESS_RIGHTS_USER_PROCESS,
        ],
        withFeedback: true,
    });

    useEffect(() => {
        dispatch({
            type: GET_BUSINESS_ACCESS_RIGHTS_START,
            payload: businessId,
        });
    }, [businessId]);

    const handleOnSubmit = (values) => {
        const { accessRightsEmailAddress } = values;
        dispatch({
            type: ADD_BUSINESS_ACCESS_RIGHTS_USER_START,
            payload: {
                entityid: businessId,
                email: accessRightsEmailAddress,
                showModal,
            },
        });
    };

    const initialValues = {
        accessRightsEmailAddress: '',
    };

    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.accessRights.title" />
            </H1>
            <P>
                <FormattedMessage id="addBusiness.page.sections.accessRights.subtitle" />
            </P>
            <Formik
                initialValues={initialValues}
                validationSchema={formAddBusinessAccessRightsSchema(users)}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => {
                    handleOnSubmit(values);
                    resetForm();
                }}
            >
                {() => <AccessRightsForm />}
            </Formik>
            <AccessRightsUsersList />
        </>
    );
};

export default AccessRights;
