import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Alert, Button, Link } from "@mui/material";
import { useDispatch } from "react-redux";
import { POST_SUPPORT_START } from "./redux/actions/SupportActions";
import RequestSupportModal from "./components/RequestSupportModal";
import TextInput from "./components/TextxInput";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(setBusinessAddress, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    //  autoCompleteRef.current.childNodes[1].childNodes[0],
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: "us" } },
  );

  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(setBusinessAddress),
  );
}

async function handlePlaceSelect(setBusinessAddress) {
  // Need this so when the user selects
  // an address our field gets updated
  // otherwise you only get the part they typed in
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;

  const event = {
    target: {
      name: "formattedAddress",
      value: query,
    },
  };

  // updateQuery(event);

  setBusinessAddress(query);
}

function LocationFormPart({
  isSubmitting,
  error,
  businessAddress,
  setBusinessAddress,
  setSelectedFromGoogle,
  formParameters,
}) {
  const autoCompleteRef = useRef(null);
  const loadScripCallback = () =>
    handleScriptLoad((e) => {
      console.log(e);
      setBusinessAddress(e);
      if (setSelectedFromGoogle) {
        setSelectedFromGoogle(e);
      }
    }, autoCompleteRef);

  useEffect(() => {
    if (typeof google === "undefined") {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyCRUvvw2xf7i1p7s7focKGh46eAFdOLxrQ&libraries=places&callback=Function.prototype`,
        loadScripCallback,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      loadScripCallback();
    }
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: 12, display: "flex", alignItems: "center" }}
      >
        Problem finding address?{" "}
        <RequestSupportModal
          caption={"Request support"}
          defaultType="ADDRESS"
          defaultValue="I cannot find my address"
          formParameters={formParameters}
        />
      </Grid>
      <TextInput
        required
        inputRef={autoCompleteRef}
        error={!!error}
        helperText={error}
        autoComplete="off"
        id="formattedAddress"
        name="formattedAddress"
        label="Address"
        value={businessAddress}
        onChange={(e) => {
          setBusinessAddress(e.currentTarget.value);
        }}
        variant="outlined"
        InputLabelProps={{ shrink: businessAddress ? true : false }}
        fullWidth
      />
    </>
  );
}
export default LocationFormPart;
