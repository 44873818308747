import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { H1, P } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { AddBusinessRequestToLinkForm, initialValues } from './AddBusinessRequestToLinkForm';
import { RESET_REQUESTTOLINK_START } from '../../redux/actions/RequestToLink';

const RequestToLink = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: RESET_REQUESTTOLINK_START });
    }, []);    
    return (
        <>
            <H1 color="primary">
                <FormattedMessage id="addBusiness.page.sections.requestToLink.title" />
            </H1>
            <P>
                <FormattedMessage id="addBusiness.page.sections.requestToLink.subtitle" />
            </P>
            <Formik initialValues={initialValues}>
                {() => <AddBusinessRequestToLinkForm />}
            </Formik>            
        </>
    );
};

export default RequestToLink;
