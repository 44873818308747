import { chain, flatMap, isEmpty, keyBy, map, merge, omit, pickBy, sortBy, values } from 'lodash';

const categoriesAndFiltersUtils = {
    flattenFiltersList: (filters) => {
        return flatMap(filters, ({ filterList }) => {
            return map(filterList, (currentFilter) => ({ ...currentFilter }));
        });
    },

    getFilterNamesByTagIds: ({ filters, tagIds }) => {
        return map(
            filters.filter((filter) => tagIds.includes(filter.id)),
            'name',
        );
    },
    initializeSelectedFilters: (selectedCategories) => {
        const initialSelectedFilters = {};

        selectedCategories
            .filter(item => item.type === 'filter')
            .forEach(filter => {
                let header = filter.crumbs.find(item => item.type === 'header');
                if (!initialSelectedFilters[header._id]) {
                    initialSelectedFilters[header._id] = {};
                }
                if (!initialSelectedFilters[header._id][filter.id]) {
                    initialSelectedFilters[header._id][filter.id] = true;
                }
            });

        return initialSelectedFilters;
    },
    getAvailableOptions: (header) => {
        return header.filters.map(filter => ({
            value: `${header._id},${filter._id}`,
            label: filter.name
        }));
    },
    processFilterValue: (values, index, header, selectedFilters) => {
        const filterValue = values.filter[index] || [];
        const availableOptions = categoriesAndFiltersUtils.getAvailableOptions(header);
        const selectedIds = [];
        if (selectedFilters[header._id]) {
            Object.keys(selectedFilters[header._id]).forEach(filterId => {
                if (selectedFilters[header._id][filterId]) {
                    selectedIds.push(`${header._id},${filterId}`);
                }
            });
        }
        const isValidValue = filterValue.every(value => availableOptions.some(option => option.value === value));
        return isValidValue ? selectedIds : [];
    },

    initializeCategoryFiltersWhenSearch: (filters) => {
        const selectedFilters = {};
        Object.values(filters).forEach(filterArray => {
            filterArray.forEach(item => {
                const [headerId, filterId] = item.split(',');
                if (!selectedFilters[headerId]) {
                    selectedFilters[headerId] = {};
                }
                selectedFilters[headerId][filterId] = !selectedFilters[headerId]?.[filterId];
            });
        });
        return selectedFilters;
    },
    extractFilterIds: (category, subcategory, filters)=>{
        let result = [];
        if(filters){
            Object.values(filters).forEach(subArray => {
                subArray.forEach(idString => {
                    const ids = idString.split(',');
                    if (ids.length > 1) {
                        result.push(...ids.slice(1));
                    }
                });
            });
        }
        result.push(category, subcategory);

        return result.filter(Boolean);
    },
    deselectSubcategoryByTagIdInCategory: ({ category, filterName, removeTagId }) => {
        const item = category.headers
            .find((header) => header.name === filterName)
            .subcategories.find((subcategory) => subcategory.id === removeTagId);

        item.selected = false;

        return category;
    },

    selectSubcategoryByTagIdsInCategoriesByCategoryName: ({
        categories,
        categoryName,
        tagIds,
        selected,
    }) => {
        const categoryFilters = categories.find(
            (category) => category.name === categoryName,
        ).headers;

        if (isEmpty(tagIds)) {
            return null;
        }

        tagIds.forEach((tagId) => {
            const item = chain(categoryFilters)
                .map('subcategories')
                .flatten()
                .find({ id: tagId })
                .value();
            item.selected = selected;
        });

        return categories;
    },

    selectSubcategoryByTagIdInCategoriesByCategoryName: ({
        categories,
        categoryName,
        tagId,
        selected,
    }) => {
        const categoryFilters = categories.find(
            (category) => category.name === categoryName,
        ).headers;

        const item = chain(categoryFilters)
            .map('subcategories')
            .flatten()
            .find({ id: tagId })
            .value();

        item.selected = selected;

        return categories;
    },

    selectCategoriesByNames: ({ categories, names }) => {
        const mergedCategories = values(merge(keyBy(categories, 'name')));

        const selectCategories = mergedCategories.filter((category) =>
            names.includes(category.name),
        );

        return sortBy(
            selectCategories.map((item) => ({
                ...item,
                selected: true,
            })),
            ['name'],
        );
    },

    removeCategoryByName: ({ categories, name }) => {
        return sortBy(
            categories.filter((category) => category.name !== name),
            ['name'],
        );
    },

    getCategoryNames: (categories) => {
        return map(categories, 'name');
    },

    getRemainingCategoryNames: ({ allCategories, remainingCategories = [] }) => {
        return categoriesAndFiltersUtils
            .getCategoryNames(allCategories)
            .filter(
                (item) =>
                    !categoriesAndFiltersUtils.getCategoryNames(remainingCategories).includes(item),
            );
    },

    getCategoryByName: ({ categories, name }) => {
        if (!name) {
            return null;
        }
        return categories.find((category) => category.name === name);
    },

    getCategoryById: ({ categories, id }) => {
        if (!id) {
            return null;
        }
        return categories.find((category) => category.id === id);
    },

    /**
     *
     * @param {array} categories
     * @param {string} name
     * @returns name, id, filters: [{filterName, filterList}]
     */
    getCategoryAndFiltersByCategoryName: ({ categories, name }) => {
        if (!name) {
            return null;
        }
        const category = categoriesAndFiltersUtils.getCategoryByName({
            categories,
            name,
        });

        const allFilterNames = categoriesAndFiltersUtils.getFilterNamesInCategory(category);

        return categoriesAndFiltersUtils.getFiltersInCategory({
            category,
            selected: false,
            allFilterNames,
        });
    },

    getSelectedTagIdsInFormik: ({ formikValues }) => {
        const tagIds = Object.keys(pickBy(omit(formikValues, 'category')));

        return !isEmpty(tagIds) && tagIds;
    },

    getDeselectedTagIdsInFormik: ({ formikValues }) => {
        const tagIds = Object.keys(
            Object.fromEntries(
                Object.entries(omit(formikValues, 'category')).filter(
                    ([_, value]) => value === false,
                ),
            ),
        );

        return !isEmpty(tagIds) && tagIds;
    },

    getSubcategoryTagIdsByCategoryName: ({ categories, name, selected }) => {
        const category = categoriesAndFiltersUtils.getCategoryByName({
            categories,
            name,
        });

        const list = flatMap(category?.headers, 'subcategories');

        if (isEmpty(list)) {
            return null;
        }

        return map(
            list.filter((listItem) => {
                if (selected) {
                    return listItem.selected === selected;
                }
                return listItem;
            }),
            (pickBy, 'id'),
        );
    },

    intialValuesSelectedTagIdsFormik: (tagIds) => {
        if (isEmpty(tagIds)) {
            return null;
        }
        return Object.assign({}, ...tagIds.map((tagId) => ({ [tagId]: true })));
    },

    remainingFilters: (filters, selectedCategories) => {
        return filters.map((filter) => ({
            ...filter,
            filters: filter.filters.filter(
                (filter) => !selectedCategories.some((selected) => selected.id === filter.id),
            ),
        }));
    },

    getOrganizedSelectedTableData: (selectedCategories) => {
        return selectedCategories.reduce(() => {
            const data = {};

            //Process categories
            selectedCategories.forEach((item) => {
                if (item.type === 'category') {
                    data[item.id || item._id] = { ...item, subcategories: {} };
                }
            });

            //Process subcategories
            selectedCategories.forEach((item) => {
                if (item.type === 'subcategory') {
                    const categoryCrumb = item.crumbs.find((crumb) => crumb.type === 'category');
                    if (categoryCrumb && data[categoryCrumb._id]) {
                        data[categoryCrumb._id].subcategories[item.id || item._id] = {
                            ...item,
                            filters: [],
                        };
                    }
                }
            });

            //Process filters
            selectedCategories.forEach((item) => {
                if (item.type === 'filter') {
                    const categoryCrumb = item.crumbs.find((crumb) => crumb.type === 'category');
                    const subcategoryCrumb = item.crumbs.find(
                        (crumb) => crumb.type === 'subcategory',
                    );
                    if (categoryCrumb && data[categoryCrumb._id]) {
                        if (subcategoryCrumb && subcategoryCrumb._id) {
                            // Add filter to subcategory if it exists
                            data[categoryCrumb._id].subcategories[
                                subcategoryCrumb._id
                            ].filters.push(item);
                        } else {
                            // Add filter directly to category if subcategory doesn't exist
                            if (!data[categoryCrumb._id].filters) {
                                data[categoryCrumb._id].filters = [];
                            }
                            data[categoryCrumb._id].filters.push(item);
                        }
                    }
                }
            });

            return data;
        }, {});
    },

    removeFilterFromSelected: (items, id) => {
        return items.filter((item) => item.id !== id);
    },

    removeSubcategoryFromSelected: (items, id) => {
        return items.filter(
            (item) => item.id !== id && !item?.crumbs.some((selected) => selected._id === id),
        );
    },

    removeCategoryFromSelected: (items, id) => {
        return items.filter(
            (item) => item.id !== id && !item?.crumbs.some((selected) => selected._id === id),
        );
    },

    mapValuesToFilters: (filters) => {
        return Object.entries(filters).flatMap(([headerId, nestedFilters]) => {
            return Object.entries(nestedFilters).map(([filterId, isSelected]) => ({
                headerId,
                filterId,
                isSelected
            }));
        });
    },

    getSelectedFilters: (subcategoryFilters, selectedFilter, selectedCategories) => {
        const selectedFilters = categoriesAndFiltersUtils.mapValuesToFilters(selectedFilter);

        if (selectedFilters.length === 0) {
            return [];
        }
        if (subcategoryFilters.length === 0) {
            return [];
        }
        const allFilters = selectedFilters.map(({ headerId, filterId }) => {
            const headerItem = subcategoryFilters?.find((item) => item._id === headerId);
            if(headerItem === undefined){
               return null;
            }
            return headerItem?.filters.find((item) => item._id === filterId);
        }).filter(item => item !== null);

        return categoriesAndFiltersUtils.checkIfItemExistsInArray(allFilters, selectedCategories);
    },

    getSelectedCategory: (categories, category, selectedCategories) => {
        const allCategories = [categories.find((cat) => cat._id === category)];
        return categoriesAndFiltersUtils.checkIfItemExistsInArray(
            allCategories,
            selectedCategories,
        );
    },

    getSelectedSubcategory: (subcategories, subcategory, selectedCategories) => {
        const allSubcategories = subcategory
            ? [subcategories.find((subcat) => subcat._id === subcategory)]
            : [];
        return categoriesAndFiltersUtils.checkIfItemExistsInArray(
            allSubcategories,
            selectedCategories,
        );
    },

    checkIfItemExistsInArray: (items, selectedCategories) => {
        if (items.length <= 0) {
            return [];
        }
        return items.filter(
            (item) => !selectedCategories.some((selected) => selected.id === item.id),
        );
    },

    handleOnSubmitFormikAddCategoryAndFilters: ({
        values,
        categories,
        subcategories,
        subcategoryFilters,
        selectedCategories,
    }) => {
        const { category, subcategory, filter } = values;

        const allCategories = categoriesAndFiltersUtils.getSelectedCategory(
            categories,
            category,
            selectedCategories,
        );
        const allSubcategories = categoriesAndFiltersUtils.getSelectedSubcategory(
            subcategories,
            subcategory,
            selectedCategories,
        );
        const selectedFilters = categoriesAndFiltersUtils.getSelectedFilters(
            subcategoryFilters,
            filter,
            selectedCategories,
        );

        const updatedSelectedCategories = selectedCategories.filter(item => {
            if(item.type === 'filter'){
                let itemHeaderId = item.crumbs.find(crumb => crumb.type === 'header')?._id;
                if (itemHeaderId && filter[itemHeaderId]) {
                    return filter[itemHeaderId][item.id];
                }
            }
            return true
        });

        return [...updatedSelectedCategories, ...allCategories, ...allSubcategories, ...selectedFilters];
    },
};

export default categoriesAndFiltersUtils;
