import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EventForm from './EventForm';
import EventList from './EventsList';

const useStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#f15a24', // Change this to your primary color
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.54)',
            '&.Mui-selected': {
                color: '#f15a24', // Change this to your primary color
            },
        },
    },
}));

const EventsTabs = () => {
    const [value, setValue] = useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="event tabs"
                    className={classes.tabs}
                >
                    <Tab label="Add Event" />
                    <Tab label="Event List" />
                </Tabs>
            </Box>
            <Box>
                {value === 0 && <EventForm />}
                {value === 1 && <EventList />}
            </Box>
        </>
    );
};

export default EventsTabs;
