import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import {FieldContainer, FormInput} from 'baseline-ui/form';
import { ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_PEOPLE } from 'common/consts';
import useGoogleMapsAutocomplete from 'utils/useGoogleMapsAutocomplete';
import { Button } from 'baseline-ui/button';
import { FEATURE_ENTITYPICKER_BY_ADDRESS } from './features';
import { GET_ENTITYPICKER_START, RESET_ENTITYPICKER_START } from '../../redux/actions/EntityPicker';
import { EntityPickerSelect } from './components';
import { EntityPickerOption } from './EntityPickerOption';
import { EntityPickerDetails } from './EntityPickerDetails';

const DEBOUNCE_OPTIONS = [
    200,
    {
        maxWait: 1000,
    },
];

/**
 * Entity Picker component.
 * @param {params.type} The type of the entity picker. Required.
 * @param {params.name} The name of the entity picker.
 * @param {params.label} The label of the entity picker.
 * @param {params.onChange} The callback function to be called when the entity is selected or cleared (null).
 * @returns The component.
 */
export const EntityPicker = (props) => {
    const { type, hidden, onChange } = props;
    const name = props.name ?? 'entitypicker';
    const label = props.label ?? `${type}.entitypicker.search.placeholder`;
    const intl = useIntl();
    const ref = useRef();
    const dispatch = useDispatch();
    if (FEATURE_ENTITYPICKER_BY_ADDRESS) {
        useGoogleMapsAutocomplete(ref);
    }
    const { values, setFieldValue } = useFormikContext();
    const { entities } = useSelector((state) => state.EntityPickerReducer);
    const searchFieldId = `${name}_search`;
    const selectedFieldId = `${name}_selected`;
    const searchQuery = values[searchFieldId];
    const selectedValue = values[selectedFieldId];
    const selectedEntity = entities && entities.find((entity) => entity._id === selectedValue);
    useEffect(() => {
        if (onChange) {
            onChange(selectedEntity);
        }
    }, [onChange, selectedEntity]);
    useEffect(() => {
        // Debounce search
        const triggerSearch = debounce(
            (search) => {
                dispatch({
                    type: GET_ENTITYPICKER_START,
                    payload: {
                        type,
                        searchQuery: search,
                    },
                });
            },
            ...DEBOUNCE_OPTIONS,
        );
        triggerSearch(searchQuery);
        return () => {
            triggerSearch.cancel();
        };
    }, [searchQuery]);
    const handleReset = () => {
        // Clear fields.
        setFieldValue(searchFieldId, '');
        setFieldValue(selectedFieldId, '');
        dispatch({ type: RESET_ENTITYPICKER_START });
        const searchField = document.querySelector(`#${searchFieldId}`);
        if (searchField) {
            // Set focus to search field.
            searchField.disabled = false;
            searchField.focus();
        }
    };
    return (
        <FieldContainer hidden={hidden}>
            <Field
                id={searchFieldId}
                name={searchFieldId}
                component={FormInput}
                label={intl.formatMessage({ id: label })}
                innerRef={ref}
                placeholder=""
                autoComplete="off"
                disabled={!!selectedValue}
                required
            />
            {selectedEntity && (
                <EntityPickerDetails
                    entity={selectedEntity}
                    ClearButton={
                        <Button
                            skin="primary"
                            icon="x"
                            btnType="outlined"
                            type="button"
                            onClick={handleReset}
                        >
                            <FormattedMessage id="addBusiness.entitypicker.clear.label" />
                        </Button>
                    }
                />
            )}
            {entities && entities.length > 0 && (
                <EntityPickerSelect hidden={selectedValue}>
                    {entities.map((entity) => (
                        <EntityPickerOption
                            selectable={false}
                            key={entity._id}
                            entity={entity}
                            selectedField={selectedValue}
                            selectedFieldId={selectedFieldId}
                        />
                    ))}
                </EntityPickerSelect>
            )}
        </FieldContainer>
    );
};
EntityPicker.propTypes = {
    type: PropTypes.oneOf([ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_PEOPLE]).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    hidden: PropTypes.bool,
};
EntityPicker.defaultProps = {
    name: undefined,
    label: undefined,
    onChange: undefined,
    hidden: false,
};

/**
 * Export as default as well.
 */
export default EntityPicker;
