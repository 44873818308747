import {
  ADD_BUSINESS_PRODUCT_SUCCESS,
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_BUSINESS_SUCCESS,
  GET_FEATURE_MAPPING_SUCCESS,
  RESET_BUSINESS_START,
  RESET_PRODUCTS_START,
  SET_PRODUCT_FILTER_SUCCESS,
  GET_BUSINESS_PEOPLE_SUCCESS,
  POST_BUSINESS_PEOPLE_SUCCESS,
} from "../actions/OwnABussinessActions";

const OwnABusinessReducer = (state = {}, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_PRODUCTS_START: {
      return {
        ...state,
        products: undefined,
      };
    }
    case RESET_BUSINESS_START: {
      const newState = { ...state };

      delete newState.business;
      delete newState.products;
      return newState;
    }
    case SET_PRODUCT_FILTER_SUCCESS: {
      return {
        ...state,
        productFilter: payload,
      };
    }
    case GET_BUSINESS_SUCCESS: {
      return { ...state, ...payload };
    }
    case ADD_BUSINESS_PRODUCT_SUCCESS:
    case GET_BUSINESS_PRODUCTS_SUCCESS: {
      return { ...state, products: payload };
    }
    case GET_BUSINESS_PEOPLE_SUCCESS: {
      return { ...state, peopleLinks: payload };
    }
    case POST_BUSINESS_PEOPLE_SUCCESS: {
      const peopleLinks = state.peopleLinks.map((link) => {
        // Replace the updated people link in the list.
        if (link._id === payload?._id) {
          return payload;
        } else {
          return link;
        }
      });
      return { ...state, peopleLinks };
    }    
    case GET_FEATURE_MAPPING_SUCCESS: {
      return {
        ...state,
        featureMapping: payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default OwnABusinessReducer;
