import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { H3, P } from 'baseline-ui/typography';
import { FieldContainer, FormTextarea } from 'baseline-ui/form';
import { EntityPicker } from 'components/EntityPicker';
import { ENTITY_TYPE_BUSSINESS } from 'common/consts';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { Field, Form, useFormikContext } from 'formik';
import { ButtonArea } from 'components/ButtonArea';
import { Button } from 'baseline-ui/button';
import { PUT_REQUESTTOLINK_START, RESET_REQUESTTOLINK_START } from 'redux/actions/RequestToLink';


/**
 * Initial values.
 */
export const initialValues = {
    // From this form.
    message: '',
    // From the EntityPicker component.
    entitypicker_search: '',
    entitypicker_selected: '',
};

/**
 * Request-to-Link form.
 */
export const AddBusinessRequestToLinkForm = () => {
    const dispatch = useDispatch();
    const [statusMessage, setStatusMessage] = useState('');
    const intl = useIntl();
    const [entity, setEntity] = useState(null);
    const { values, resetForm } = useFormikContext();
    const { _id: requestingEntityId, name: fullName, position } = useSelector(businessFormSelector);
    const { status, code } = useSelector(({ RequestToLinkReducer }) => RequestToLinkReducer);
    useEffect(() => {
        if (statusMessage) {
            return;
        }
        if (status === 'ERROR') {
            setStatusMessage(
                intl.formatMessage({
                    id: `addBusiness.page.sections.requestToLink.error.${code ?? 'default'}`,
                }),
            );
        } else if (status === 'SUCCESS') {
            dispatch({ type: RESET_REQUESTTOLINK_START });
            resetForm();
            setStatusMessage(
                intl.formatMessage({
                    id: 'addBusiness.page.sections.requestToLink.success',
                }),
            );
        } else if (status) {
            // eslint-disable-next-line no-console
            console.warn(
                `AddBusinessRequestToLinkForm : Unrecognized 'RequestToLinkReducer.status': ${status}`,
            );
        }
    }, [status, code, statusMessage, setStatusMessage]);
    const defaultMessage = entity
        ? intl.formatMessage(
              { id: 'addBusiness.page.sections.requestToLink.defaultMessage' },
              {
                  fullName,
                  position,
                  businessName: entity.name,
              },
          )
        : intl.formatMessage({ id: 'addBusiness.page.sections.requestToLink.message.placeholder' });
    const disableSubmit = !entity;
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (disableSubmit) {
            return;
        }
        const splitName = fullName.split(' ');
        const firstName = splitName[0];
        const lastName = splitName.slice(1).join(' ');
        const payload = {
            firstName,
            lastName,
            position,
            requestingEntityId,
            entityId: entity._id,
            message: values.message || defaultMessage,
        };
        dispatch({ type: PUT_REQUESTTOLINK_START, payload });
    };
    const onAcknowledge = () => {
        dispatch({ type: RESET_REQUESTTOLINK_START });
        setStatusMessage('');
    };
    const hideControls = !!statusMessage;
    return (
        <Form noValidate>
            {statusMessage && <H3>{statusMessage}</H3>}
            <FieldContainer hidden={hideControls}>
                <P>
                    <FormattedMessage id="addBusiness.page.sections.requestToLink.findbusiness.subtitle" />
                </P>
                <EntityPicker
                    label="addBusiness.page.sections.requestToLink.findbusiness.placeholder"
                    type={ENTITY_TYPE_BUSSINESS}
                    onChange={(ent) => setEntity(ent)}
                />
            </FieldContainer>
            <FieldContainer hidden={hideControls}>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.page.sections.requestToLink.message.title" />
                </H3>
                <Field
                    id="message"
                    name="message"
                    required
                    rows={16}
                    component={FormTextarea}
                    label={defaultMessage}
                />
            </FieldContainer>
            <ButtonArea>
                {statusMessage ? (
                    <Button type="button" skin="primary" onClick={onAcknowledge}>
                        <FormattedMessage id="addBusiness.page.sections.requestToLink.findbusiness.acknowledge" />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        skin="primary"
                        disabled={disableSubmit}
                        onClick={onSubmit}
                    >
                        <FormattedMessage id="addBusiness.page.sections.requestToLink.findbusiness.submit" />
                    </Button>
                )}
            </ButtonArea>
        </Form>
    );
};

/**
 * Export as default as well.
 */
export default AddBusinessRequestToLinkForm;
